import React from 'react';
import './Router_page.css';
import "../Features/script";
import Idli_dosa_vada from './Idli_dosa_vada';
import Sambhar from './Sambhar';
import Chutney from './Chutney';
import Gravies from './Gravies';
import image from '../assests/Screenshot 2024-02-04 164729.png'
import image3 from '../assests/basegravy.png'
import table_img3 from '../assests/basegravy_table.png'
import pwimg3 from '../assests/basegravy1.png'
import image1 from '../assests/sambhar.png'
import table_img1 from '../assests/table_sambhar.png'
import pwimg1 from '../assests/sambhar1.png'
import image2 from '../assests/peanutchutneynew.png'
import image_old2 from '../assests/peanutchutney.png'
import table_img2 from '../assests/peanutchutney_table.png'

function Router_Page() {
  return (
    <div>
        <div className='route_page' id='jain-products'>
            <div className='Title'>
                <h1>JAIN RANGE OF PRODUCTS</h1> 
            </div>
            <div className="Steps">
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item">A. Jain Base Gravy</li>
                    <li className="Steps-item">B. Jain Vegetable Udupi Sambhar</li>
                    <li className="Steps-item">C. Jain Peanut Chutney</li>
                    <li className="Steps-item">D. Jain Tomato Chutney</li>
                </ul>
                <div className='image'>
                    <img src={image} alt='food_image' width='787px' height='450px'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>LORMAN JAIN BASE GRAVY</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg3} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 5-6 times the quantity ( Eg: 5-6 Ltrs Of Water per Kg of powder).</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka using additional oil if required</li>
                    <li className="Steps-item"> Stir with add-ons till dish reaches desired thickness</li>
                    <li className="Steps-item"> Popularly used for multiple veg/ Non-veg dishes</li>
                    
                </ul>
                <div className='table_image'>
                                <h2>Fact Sheet</h2>
                        <img src={table_img3} alt='table_image'width='750px' height='750px'/>
                        <img src={image3} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title' id='sambar'>
                <h1>LORMAN JAIN UDUPI TIFFIN SAMBHAR</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg1} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list">
                    <li className="Steps-item"> Boil Vegetables until tender</li>
                    <li className="Steps-item"> Add 6 times the weight of Sambhar powder in water</li>
                    <li className="Steps-item"> Heat Sambhar, Vegetables and water for 5-10mins</li>
                    <li className="Steps-item"> Add tempering if Desired</li>
                    <li className="Steps-item"> Season with salt and jaggery to taste</li>
                </ul>
                <div className='table_image'>
                        <h2>Fact Sheet</h2>
                        <img src={table_img1} alt='table_image' width='750px' height='750px'/>
                        <img src={image1} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page' id='chutney'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>LORMAN JAIN PEANUT CHUTNEY</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={image2} alt='food_image' width='450px' height='100px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water equal to the quantity ( Eg: 1 Ltr of Water per Kg of powder)</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka if required</li>
                    <li className="Steps-item"> Mix thoroughly & serve</li>
                    
                </ul>
                    <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img2} alt='table_image'width='750px' height='750px'/>
                    <img src={image_old2} alt='food_image' width='450px' height='100px' className='side-by-side-image'/>
                    </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>LORMAN JAIN TOMATO CHUTNEY</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg3} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 2 times the quantity ( Eg: 2 Ltr of Water per Kg of powder)</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka if required</li>
                    <li className="Steps-item"> Mix thoroughly & serve</li>
                </ul>
                    <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img3} alt='table_image' width='750px' height='750px'/>
                    <img src={image3} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Router_Page