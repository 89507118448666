import React from 'react';
import './Router_page.css';
import "../Features/script";
import Navbar from './Navbar'
import image from '../assests/yellowgravy.png'
import table_img from '../assests/yellowgravy_table.png'
import pwimg from '../assests/yellowgravy1.png'
import image2 from '../assests/brownoniongravy.png'
import table_img2 from '../assests/brownoniongravy_table.png'
import pwimg2 from '../assests/brownoniongravy1.png'
import image3 from '../assests/basegravy.png'
import table_img3 from '../assests/basegravy_table.png'
import pwimg3 from '../assests/basegravy1.png'
import image4 from '../assests/ghatimasala.png'
import table_img4 from '../assests/ghatimasala_table.png'
import pwimg4 from '../assests/ghatimasala1.png'
import image5 from '../assests/redgravy.png'
import pwimg5 from '../assests/redgravy1.png'
import table_img5 from '../assests/redgravy_table.png'
import image6 from '../assests/whitegravy.png'
import table_img6 from '../assests/whitegravy_table.png'
import pwimg6 from '../assests/whitegravy1.png'

function Router_Page() {
  return (
    <div>
        <div className='scroll-more'>
            <h1>Scroll down<br/>for more</h1>
        </div>
        <div className='route_page' id='gravies'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Yellow Gravy</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 5 times the quantity ( Eg: 5 Ltrs Of Water per Kg of powder). </li>
                    <li className="Steps-item"> More water can be added for a thin gravy</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka using additional oil/ butter/ Khoya if required</li>
                    <li className="Steps-item"> Stir with add-ons till dish reaches desired thickness</li>
                    <li className="Steps-item"> Popularly used for Korma, Kofta etc.</li>
                    
                </ul>
                <div className='table_image'>
                        <h2>Fact Sheet</h2>
                        <img src={table_img} alt='table_image'width='750px' height='750px'/>
                        <img src={image} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Brown Onion Gravy</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg2} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 5 times the quantity ( Eg: 5 Ltrs Of Water per Kg of powder).</li>
                    <li className="Steps-item"> More water can be added for a thin gravy</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka using additional oil/Tomato Puree if required</li>
                    <li className="Steps-item"> Stir with add-ons till dish reaches desired thickness</li>
                    <li className="Steps-item"> Popularly used for Panneer/Mushroom Kadai etc.</li>
                    
                </ul>
                <div className='table_image'>
                                <h2>Fact Sheet</h2>
                        <img src={table_img2} alt='table_image'width='750px' height='750px'/>
                        <img src={image2} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Base Gravy</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg3} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 5-6 times the quantity ( Eg: 5-6 Ltrs Of Water per Kg of powder).</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka using additional oil if required</li>
                    <li className="Steps-item"> Stir with add-ons till dish reaches desired thickness</li>
                    <li className="Steps-item"> Popularly used for multiple veg/ Non-veg dishes</li>
                    
                </ul>
                <div className='table_image'>
                                <h2>Fact Sheet</h2>
                        <img src={table_img3} alt='table_image'width='750px' height='750px'/>
                        <img src={image3} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Ghati Masala</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg4} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilution to be decided based on dry/ gravy dish being prepared ( 3-5 times of powder used).</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Popularly used for Usal/ Misal, Bhindi Subji etc</li>
                   
                </ul> 
                <div className='table_image'>
                                <h2>Fact Sheet</h2>
                        <img src={table_img4} alt='table_image'width='750px' height='750px'/>
                        <img src={image4} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Red Makhni gravy</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg5} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 5-7 times the quantity ( Eg: 5-7 Ltrs of Water per Kg of powder).</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka using additional oil/butter if required</li>
                    <li className="Steps-item"> Stir with add-ons ( Panneer/ Dal/ Tofu) till dish reaches desired thickness</li>
                    <li className="Steps-item"> Ideal for all Makhni dishes.</li>
                    
                </ul>
                <div className='table_image'>
                            <h2>Fact Sheet</h2>
                        <img src={table_img5} alt='table_image'width='750px' height='750px'/>
                        <img src={image5} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman White Gravy Masala</h1>
                <h2><br/>Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg6} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 5-6 times the quantity ( Eg: 5-6 Ltrs Of Water per Kg of powder).</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add butter/oil if required</li>
                    <li className="Steps-item"> Stir with add-ons till dish reaches desired thickness</li>
                    <li className="Steps-item"> Popularly used wherever rich and creamy preparations are required such as Kofta/ Korma/ Pasanda</li>
                    
                </ul>
                <div className='table_image'>
                            <h2>Fact Sheet</h2>
                        <img src={table_img6} alt='table_image' width='750px' height='750px'/>
                        <img src={image6} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Router_Page