import React from 'react';
import './Router_page.css';
import "../Features/script";
import Navbar from './Navbar'
import image from '../assests/peanutchutneynew.png'
import image_old from '../assests/peanutchutney.png'
import table_img from '../assests/peanutchutney_table.png'
import pwimg from '../assests/peanutchutneypowder.png'
import image2 from '../assests/gingerchutney.png'
import table_img2 from '../assests/gingerchutney_table.png'
import image3 from '../assests/tomatochutney.png'
import table_img3 from '../assests/tomatochutney_table.png'
import image4 from '../assests/pudichutney.png'
import pwimg4 from '../assests/pudichutney1.png'
import table_img4 from '../assests/pudichutney_table.png'
import pwimg2 from '../assests/gingerchutney1.png'
import pwimg3 from '../assests/tomatochutney1.png'

function Router_Page() {
  return (
    <div>
    <div className='scroll-more'>
        <h1>Scroll down<br/>for more</h1>
    </div>
        <div className='route_page' id='chutney'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Peanut Chutney</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={image} alt='food_image' width='450px' height='100px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water equal to the quantity ( Eg: 1 Ltr of Water per Kg of powder)</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka if required</li>
                    <li className="Steps-item"> Mix thoroughly & serve</li>
                    
                </ul>
                    <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img} alt='table_image'width='750px' height='750px'/>
                    <img src={image_old} alt='food_image' width='450px' height='100px' className='side-by-side-image'/>
                    </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Ginger Chutney</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg2} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water twice the quantity ( Eg: 2 Ltr of Water per Kg of powder)</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka if required</li>
                    <li className="Steps-item"> Mix thoroughly & serve</li>
                </ul>
                    <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img2} alt='table_image' width='750px' height='750px'/>
                    <img src={image2} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                    </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Tomato Chutney</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg3} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Dilute powder with water 2 times the quantity ( Eg: 2 Ltr of Water per Kg of powder)</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Add tadka if required</li>
                    <li className="Steps-item"> Mix thoroughly & serve</li>
                </ul>
                    <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img3} alt='table_image' width='750px' height='750px'/>
                    <img src={image3} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                    </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Chutney Pudi</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg4} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" data-aos="fade-down">
                    <li className="Steps-item"> Mix Chutney pudi with Oil/ Ghee (as required)</li>
                    <li className="Steps-item"> Toss/ Temper steamed idlis with the chutney pudi</li>
                    <li className="Steps-item"> Serve with Chutney/ Sambhar</li>
                    
                    
                </ul>
                <div className='table_image'>
                    <h2>Fact Sheet</h2>
                    <img src={table_img4} alt='table_image' width='750px' height='750px'/>
                    <img src={image4} alt='food_image' width='450px' height='100px'className='side-by-side-image'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Router_Page