import React , {useEffect,useLocation}from 'react';
import '../Main_cont/About.css';
import logo1 from '../assests/IMG_0832.png'
import logo2 from '../assests/Shopping_cart.png'
import logo3 from '../assests/Kitchen.png'
import logo4 from '../assests/Chef_hat.png'
import "../Features/script";

function About() {
  

    return (
      <div className='main2'>
            <div className='homepage3' id='about-us-id'>
                  <div className="about-us" data-aos="fade-right">
                      <h2>ABOUT US</h2>
                      <p>
                          <strong>LORMAN INTEGRATED FOOD EVOLUTION (LIFE)</strong> is a Lorman Group initiative in the field of dehydrated ready-to-cook food products (RTC) for commercial users. Carrying forward the legacy built by India’s first completely indigenous induction-based kitchens, the group boasts of over 3000 customers pan India painstakingly built over the last 12 years.
                      </p>
                      <h2>What is a Dehydrated food product?</h2>
                      <p>
                          Preparation of food and reducing moisture content leads to Dehydrated food products.
                          Heat Pump Dehydrators are used to create these products that offer enhanced shelf life (Maximum of 6 Months)
                          without the use of Preservatives and helps in Just in time mixing for tasty
                          dishes.
  
                      </p>
                  </div>
              <div className='imgs3' data-aos="fade-left">
                  <img src={logo1} className='img1' alt='canteen' width='128px' height='128px'/>
                  <img src={logo2} className='img2' alt='canteen' width='128px' height='128px'/>
                  <img src={logo3} className='img3' alt='canteen' width='128px' height='128px' />
                  <img src={logo4} className='img4' alt='canteen' width='128px' height='128px'/>
              </div>
            </div>
        </div>
    );
}

export default About;