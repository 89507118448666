import React from 'react'
import './Product.css';
import { IconButton,Button } from '@mui/material'
import '../Features/script';
import image1 from '../assests/wp6734901.jpg';
import image2 from '../assests/Screenshot 2024-02-04 164551.png';
import image3 from '../assests/Screenshot 2024-02-04 164622.png';
import image4 from '../assests/Screenshot 2024-02-04 164729.png';
import image5 from '../assests/Screenshot 2024-02-04 164701.png';
import image6 from '../assests/Screenshot 2024-02-04 164643.png';
import {useNavigate} from 'react-router-dom';
import slide_image from '../assests/image-3.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper'
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

SwiperCore.use([Navigation,Pagination,EffectCoverflow]);



function Card(props) {

    const nav = useNavigate();
    return (
        <div className='card hover-effect' data-aos="flip-up">
                    <div className="wrapper">
                        <div className="banner-image"><img src={props.image} alt={props.alt} /></div>
                        <h1>{props.title}</h1>
                    </div>
                    <div className="button-wrapper"> 
                        <Button class="btn fill" onClick={()=>{nav(props.title)}}>View More</Button>
                    </div>
        </div>
    );
}

function Product() {

  return (
    <div className='products' id='product-id'>
        <div className='product-header' data-aos="fade-up"><h1>Products</h1></div>
        <Card image = {image1} title="Idli Dosa and Vada" alt="Idli Dosa and Vada"/>
        <Card image = {image2} title="Sambhar" alt="Sambhar"/>
        <Card image = {image3} title="Chutney" alt="Chutney"/>
        <Card image = {image4} title="Gravies" alt="Gravies"/>
        <Card image = {image5} title="Fruits and Vegetables" alt="Fruits and Vegetables"/>
        <Card image = {image6} title="Jain Range of Product" alt="Jain Range of Product"/>
    </div>
    )
}

export default Product;