import React from 'react';
import './Router_page.css';
import "../Features/script";
import Navbar from './Navbar'
import image from '../assests/sambhar.png'
import table_img from '../assests/table_sambhar.png'
import image2 from '../assests/sambhar2.png'
import table_img2 from '../assests/table_sambhar2.png'
import pwimg from '../assests/sambhar1.png'
import pwimg2 from '../assests/sambhar21.png'


function Sambhar() {
  return (
    <div>
        <div className='scroll-more'>
            <h1>Scroll down<br/>for more</h1>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title' id='sambar'>
                <h1>Lorman Udupi Tiffin Sambhar</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list">
                    <li className="Steps-item"> Boil Vegetables until tender</li>
                    <li className="Steps-item"> Add 6 times the weight of Sambhar powder in water</li>
                    <li className="Steps-item"> Heat Sambhar, Vegetables and water for 5-10mins</li>
                    <li className="Steps-item"> Add tempering if Desired</li>
                    <li className="Steps-item"> Season with salt and jaggery to taste</li>
                </ul>
                <div className='table_image'>
                        <h2>Fact Sheet</h2>
                        <img src={table_img} alt='table_image' width='750px' height='750px'/>
                        <img src={image} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman South Indian Tiffin Sambhar</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg2} alt='food_image' width='500px' height='450px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list">
                    <li className="Steps-item"> Boil Vegetables until tender</li>
                    <li className="Steps-item"> Add 6 times the weight of Sambhar powder in water</li>
                    <li className="Steps-item"> Heat Sambhar, Vegetables and water for 5-10mins</li>
                    <li className="Steps-item"> Add tempering if Desired</li>
                    <li className="Steps-item"> Season with salt and jaggery to taste (If neccessary)</li>
                </ul>
                    
                    <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img2} alt='table_image' width='750px' height='750px'/>
                    <img src={image2} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Sambhar