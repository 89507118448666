import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Main_cont/Navbar';
import Sambhar_Page from './Main_cont/Sambhar';
import Main_cont from './Maincontainer';
import Idli_dosa_vada from './Main_cont/Idli_dosa_vada';
import Chutney from './Main_cont/Chutney';
import Gravies from './Main_cont/Gravies';
import Jain_prods from './Main_cont/Jain_prods';
import Fruits_vegies from './Main_cont/Fruits_vegies';
import whatsappicon from './assests/whatsapp.png';
import phoneicon from './assests/phone.png';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return (
    <div className='App'>
      <div className='whatsapp'>
        <a href='https://wa.me/919845546434' target='blank'>
          <h2>
            <img src={whatsappicon} alt='whatsapp' />
            <span className='text_chat'>Chat with us</span>
          </h2>
        </a>
      </div>
      <div className='phone'>
        <a href='tel:+919845546434' target='blank'>
          <h2>
            <img src={phoneicon} alt='phone' />
            <span className='text_chat'>Call Us</span>
          </h2>
        </a>
      </div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Main_cont />} />
        <Route path='/Sambhar' element={<Sambhar_Page targetId='sambhar' />} />
        <Route path='/Idli Dosa and Vada' element={<Idli_dosa_vada targetId='idli'/>} />
        <Route path='/Chutney' element={<Chutney targetId='chutney' />} />
        <Route path='/Gravies' element={<Gravies targetId='gravies' />} />
        <Route path='/Jain Range of Product' element={<Jain_prods targetId='jain-products' />} />
        <Route path='/Fruits and Vegetables' element={<Fruits_vegies targetId='fruits-vegetables' />} />
      </Routes>
    </div>
  );
}

export default App;
