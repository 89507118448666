import React, {useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';
import '../Features/script';
import { IconButton,Button } from '@mui/material'
import {useNavigate} from 'react-router-dom';

function Navbar() {
  const [buttontype,setButtontype] = useState(true);
  const nav = useNavigate();

  return (  
    <div className='navbar'>
      <div onClick={()=>{nav('/', { state: { targetId: "home-id" } })}}>
        <Button>
        <img src="lorman_logo.png" 
        alt='com_logo' width= '120px' height='68.18px' id='navlogo'/></Button>
      </div>
        <div className="navbar-text">
          <ul className={buttontype?"navbar-menu":"navbar-menu active"}>
            <Button onClick={()=>{nav('/', { state: { targetId: "home-id" } });
                             {setButtontype((prevValue)=>{
                              return !prevValue;
                            })};}}><li className="navbar-item">HOME</li></Button>
            <Button onClick={()=>{nav('/', { state: { targetId: "weare-id" } });
                             {setButtontype((prevValue)=>{
                              return !prevValue;
                            })};}}><li className="navbar-item">WE-ARE</li></Button>
            <Button onClick={()=>{nav('/', { state: { targetId: "about-us-id" } });
                             {setButtontype((prevValue)=>{
                              return !prevValue;
                            })};}}><li className="navbar-item">ABOUT US</li></Button>
            <Button onClick={()=>{nav('/', { state: { targetId: "product-id" } });
                             {setButtontype((prevValue)=>{
                              return !prevValue;
                            })};}}><li className="navbar-item">PRODUCTS</li></Button>
            <Button onClick={()=>{nav('/', { state: { targetId: "contact-us-id" } });
                             {setButtontype((prevValue)=>{
                              return !prevValue;
                            })};}}><li className="navbar-item">CONTACT US</li></Button>
          </ul>
        </div>
        <div className='mobile-nav'>
          <IconButton onClick={()=>{setButtontype((prevValue)=>{
            return !prevValue;
          })}}>
            {buttontype && <MenuIcon sx={{color: '#000000'}}/>}
            {!buttontype && <CloseIcon sx={{color: '#000000'}}/>}
          </IconButton>
        </div>
    </div>
  )
}

export default Navbar