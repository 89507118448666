import React, { useState, useEffect } from 'react';
import './Weare.css';
import image from '../assests/wp7845794.jpg';
import image1 from '../assests/IMG_0832.png';
import image2 from '../assests/Shopping_cart.png';
import image3 from '../assests/Kitchen.png';
import image4 from '../assests/Chef_hat.png';
import image5 from '../Lorman-Collage-with-text-2.jpg';
import { Reveal } from '../Features/Reveal';
import imageMobile from '../assests/mobile.jpg';  

function Slide({ src, isActive }) {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsAnimating(true); 
      const timeout = setTimeout(() => {
        setIsAnimating(false); 
      }, 3000); 
      return () => clearTimeout(timeout); 
    }
  }, [isActive]);

  return (
    <img
      decoding="async"
      className={`img-slide ${isActive ? 'active' : ''} ${isAnimating ? 'animate' : ''}`}
      src={src} 
      alt=""
    />
  );
}

function Content({ title, subTitle, isActive, imageSrc , isWeare}) {
    useEffect(() => {
      if (isActive) {
        const elements = document.querySelectorAll('.content.active [data-aos]');
        elements.forEach(element => {
          element.classList.add('aos-animate');
        });
      }
    }, [isActive]);
    
    return (
      <div className={`content ${isActive ? 'active' : ''}`} >
        <div className={`heading ${isWeare ? 'display':''}`}>
          <h1 data-aos={isActive ? 'fade-up' : ''} className={isActive ? 'slide-animation' : ''} style={{ paddingTop: '20px', textAlign: 'center', marginBottom:"80px" ,fontFamily: "'Inter', sans-serif"}}>
              WE ARE.
          </h1>
        </div>
        <div className='borderStyle isActive'>
            <img src={imageSrc} alt="" style={{ width: `${isWeare ? '175px':'100%'}`, height: `${isWeare ? '175px':'100%'}`, display: 'block', margin: '0 auto', position: 'relative', top: '-50px' }} />
            <h2 data-aos={isActive ? 'fade-up' : ''} className={isActive ? 'slide-animation' : ''} style={{ paddingTop: '20px', textAlign: 'center' }}>
            {title}<br /><br/><span>{subTitle}</span>
            </h2>
        </div>
      </div>
    );
  }

  
function SliderNavigation({ numSlides, activeIndex, handleSlideChange }) {
  const navBtns = Array.from({ length: numSlides }, (_, index) => (
    <div key={index} className={`nav-btn ${index === activeIndex ? 'active' : ''}`} onClick={() => handleSlideChange(index)}></div>
  ));

  return <div className="slider-navigation">{navBtns}</div>;
}

function Imageslider() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % 5); 
    }, 3000); 
    return () => clearInterval(intervalId); 
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div id='weare-id'>
      <Reveal>
        <section className="home">
          <div className='largescreen'>
            <Slide src={image} isActive={activeIndex === 0} />
            <Slide src={image} isActive={activeIndex === 1} />
            <Slide src={image} isActive={activeIndex === 2} />
            <Slide src={image} isActive={activeIndex === 3} />
            <Slide src={image} isActive={activeIndex === 4} />
          </div>

          <div className='smallscreen'>
            <Slide src={imageMobile} isActive={activeIndex === 0} />
            <Slide src={imageMobile} isActive={activeIndex === 1} />
            <Slide src={imageMobile} isActive={activeIndex === 2} />
            <Slide src={imageMobile} isActive={activeIndex === 3} />
            <Slide src={imageMobile} isActive={activeIndex === 4} />
          </div>

          
          <Content title="Guinness" subTitle="World Record" isActive={activeIndex === 0} imageSrc={image5} isWeare={false}/>
          <Content title="Institutional & Industrial" subTitle="Canteen" isActive={activeIndex === 1} imageSrc={image1} isWeare={true}/>
          <Content title="Chain of Restaurant" subTitle="Supermarket" isActive={activeIndex === 2} imageSrc={image2} isWeare={true}/>
          <Content title="Cloud" subTitle="Kitchen" isActive={activeIndex === 3} imageSrc={image3} isWeare={true}/>  
          <Content title="Large Catering" subTitle="Establishment" isActive={activeIndex === 4} imageSrc={image4} isWeare={true}/>

          <SliderNavigation numSlides={5} activeIndex={activeIndex} handleSlideChange={handleSlideChange} /> 
        </section>
      </Reveal>
    </div>
  );
}

export default Imageslider;
