import React from 'react';
import './Router_page.css';
import "../Features/script";
import Navbar from './Navbar'
import image from '../assests/dosaprod.png'
import table_img from '../assests/dosaprod_table.png'
import image2 from '../assests/ravaidli.png'
import table_img2 from '../assests/ravaidli_table.png'
import pwimg2 from '../assests/ravaidli2.png'
import image3 from '../assests/riceidli.png'
import image3_powder from '../assests/riceidli2.png'
import table_img3 from '../assests/riceidli_table.png'
import image4 from '../assests/medhuvada.png'
import table_img4 from '../assests/medhuvada_table.png'
import pwimg4 from '../assests/medhuvada2.png'
import pwimg1 from '../assests/dosa2.png'

function Idli_dosa_vada() {
  return (
    <div>
        <div className='scroll-more'>
            <h1>Scroll down<br/>for more</h1>
        </div>
        <div className='route_page'>
                <div className='top-part'>
                    <div className='stamp'>
                    <h3>FERMENTED AND<br/>DEHYDRATED</h3>
                </div>
                <div className='Title-dosa'>
                    <h1>Lorman Dosa</h1>
                    <h2><br/> Recommendations for use</h2>
                </div>
                </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg1} alt='food_image' width='787px' height='450px' className="side-by-side-image"/>
                </div>
                <ul className="Steps_list" >
                    <li className="Steps-item"> Add water 2.0 times the quatity of Dosa Powder for batter
                        (eg: For 1kg of Powder add 2000gms of water)</li>
                    <li className="Steps-item"> Keep the batter in a container for 30 minutes</li>
                    <li className="Steps-item"> Taste the batter and check if more salt needs to be added</li>
                    <li className="Steps-item"> Add a tsp of Oil (Optional)</li>
                    <li className="Steps-item"> Prepare your dosa</li>
                    
                </ul>
                <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img} alt='table_image' width='750px' height='750px'/>
                        <img src={image} alt='food_image' width='450px' height='450px' className="side-by-side-image"/>
                </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp'>
                <h3>FERMENTED AND<br/>DEHYDRATED</h3>
                </div>
                <div className='Title-dosa'>
                <h1>Lorman Rava Idli</h1>
                <h2><br/> Recommendations for use</h2>
                </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg2} alt='food_image' width='787px' height='450px'className="side-by-side-image"/>
                </div>
                <ul className="Steps_list" >
                    <li className="Steps-item"> Add water 1.8 times the quantity of Idli Powder for batter
                        (eg: For 1kg of Powder add 1800gms of water)</li>
                    <li className="Steps-item"> Keep the batter in a container for 30 minutes</li>
                    <li className="Steps-item"> Taste the batter and check if more salt needs to be added</li>
                    <li className="Steps-item"> Add a tsp of Oil (Optional)</li>
                    <li className="Steps-item"> After 30 minutes, pour batter into Steamer for 20 minutes.</li>
                    <li className="Steps-item"> Idlis can be taken out and served hot with accompaniments</li>
                </ul>
                <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img2} alt='table_image' width='750px' height='750px'/>
                    <img src={image2} alt='food_image' width='450px' height='450px' className="side-by-side-image"/>
                    </div>
            </div>
        </div>
        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp'>
                <h3>FERMENTED AND<br/>DEHYDRATED</h3>
            </div>
            <div className='Title-dosa'>
                <h1>Lorman Rice Idli</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
    <div className="Steps">
        <div className='image'>
            <img src={image3_powder} alt='food_image' className="side-by-side-image" />
         </div>
        <ul className="Steps_list" >
            <li className="Steps-item"> Add Water 1.8 times the quantity of Idli Powder for Batter 
            ( For eg: For 1 Kg powder, add 1800 gms water)</li>
            <li className="Steps-item"> Keep the batter in a container for 30 minutes</li>
            <li className="Steps-item"> Taste the batter and check if more salt needs to be added</li>
            <li className="Steps-item"> Add a tsp of Oil (Optional)</li>
            <li className="Steps-item"> After 30 minutes, pour batter into Steamer for 20 minutes.</li>
            <li className="Steps-item"> Idlis can be taken out and served hot with accompaniments.</li>
            
        </ul>
        
        <div className='table_image'>
                <h2>Fact Sheet</h2>
                <img src={table_img3} alt='table_image' width='750px' height='750px'/>
            <img src={image3} alt='food_image' className="side-by-side-image" />
        </div>
    </div>
</div>

        <div className='route_page'>
            <div className='top-part'>
                <div className='stamp1'>
                    <h3>DEHYDRATED</h3>
                </div>
            <div className='Title'>
                <h1>Lorman Medhu Vada</h1>
                <h2><br/> Recommendations for use</h2>
            </div>
            </div>
            <div className="Steps">
                <div className='image'>
                    <img src={pwimg4} alt='food_image' width='787px' height='450px' className='side-by-side-image'/>
                </div>
                <ul className="Steps_list" >
                    <li className="Steps-item"> Dilute powder with water 1.5 times the quantity (1500 grams of water per Kg of powder)</li>
                    <li className="Steps-item"> Add Salt to taste (as required)</li>
                    <li className="Steps-item"> Taste can be further enhanced with a mix of Coriander Leaves, Coconut, Black pepper, Green chillies, Ginger/Curry leaves</li>
                    <li className="Steps-item"> Mix thoroughly and wait for 30 minutes</li>
                    <li className="Steps-item"> The batter is now ready for frying vada in a Kadai</li>
                    <li className="Steps-item"> Serve hot with accompaniments</li>
                    
                    
                </ul>
                <div className='table_image'>
                            <h2>Fact Sheet</h2>
                            <img src={table_img4} alt='table_image' width='750px' height='750px'/>
                            <img src={image4} alt='food_image' width='787px' height='450px'className='side-by-side-image'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Idli_dosa_vada