import React,{useEffect, useRef} from 'react'
import {useTypewriter, Cursor} from 'react-simple-typewriter'
import dosapic from '../assests/dosa.png';
import "../Features/script";
import './Home.css';

function Home() {

    const [text] = useTypewriter({
        words:[' taste the same everyday ?',' get boring ?',' lack variety ?',' need more flavor ??'],
        loop: {}
    })
     const imageRef = useRef(null);

    
     useEffect(() => {
         window.addEventListener('scroll', event => {
           requestAnimationFrame(() => {
             const rotation = window.scrollY / 8;
             if(imageRef.current){
                imageRef.current.style.transform = `rotate(${rotation}deg)`;
             }
           });
         });
       }, []);

    // window.onscroll = function() {
    //     var theta = document.documentElement.scrollTop / 500 % Math.PI;
    //     if(document.getElementById('js-logo')){
    //         document.getElementById('js-logo').style.transform ='rotate(' + theta + 'rad)';
    //     }
    // }
    
  return (
    <div className='main1'>
        <div className='homepage1' id='home-id'>
            <div className='dosa' data-aos="fade-right">
                <img ref={imageRef} src={dosapic}
                alt='dosapicturew' width='700px' height='700px'/>
            </div>
            <div className='text-1'>
                    <div data-aos="fade-left" id='contlist'>
                        <h2 className='contentlist'>
                            Are you Looking for
                        </h2>
                        <ul className='contentlist'>
                            <li className='contentlistitem'> Consitent Quality throughout the year</li>
                            <li className='contentlistitem'> Minimise wastage in the kitchen</li>
                            <li className='contentlistitem'> Reduce Cost of food</li>
                            <li className='contentlistitem'> Reduction in Manpower</li>
                        </ul>
                    </div>
            </div>
        </div>
    </div>
  );
}

export default Home;