import React, { useState, useEffect } from 'react';
import './Imageslider.css';
import '../Features/script';
import image1 from '../assests/bkimage.png';
import image2 from '../assests/sliderbk.jpg';
import { Reveal } from '../Features/Reveal';

function Slide({ src, isActive }) {
  const [isAnimating, setIsAnimating] = useState(false);

  
  useEffect(() => {
    if (isActive) {
      setIsAnimating(true); 
      const timeout = setTimeout(() => {
        setIsAnimating(false); 
      }, 1000); 
      return () => clearTimeout(timeout); 
    }
  }, [isActive]);

  return (
    <img
      decoding="async"
      className={`img-slide ${isActive ? 'active' : ''} ${isAnimating ? 'animate' : ''}`}
      src={src}
      alt=""
    />
  );
}

function Content({ title, subTitle, isActive }) {
  useEffect(() => {
    if (isActive) {
      const elements = document.querySelectorAll('.content.active [data-aos]');
      elements.forEach(element => {
        element.classList.add('aos-animate');
      });
    }
  }, [isActive]);

  return (
    <div className={`content ${isActive ? 'active' : ''}`} style={{ border: '10px solid #c89600', height: '500px',}}>
      <h1 data-aos={isActive ? 'fade-up' : ''} className={isActive ? 'slide-animation' : ''} style={{ paddingTop: '150px' }} >
        {title}<br /><br /><span>{subTitle}</span>
      </h1>
    </div>
  );
}


function SliderNavigation({ numSlides, activeIndex, handleSlideChange }) {
  const navBtns = Array.from({ length: numSlides }, (_, index) => (
    <div key={index} className={`nav-btn ${index === activeIndex ? 'active' : ''}`} onClick={() => handleSlideChange(index)}></div>
  ));

  return <div className="slider-navigation">{navBtns}</div>;
}

function Imageslider() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % 5); 
    }, 2000); 
    return () => clearInterval(intervalId); 
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Reveal>
    <section className="home2">
      
      <div className='large'>
        <Slide src={image1} isActive={activeIndex === 0} />
        <Slide src={image1} isActive={activeIndex === 1} />
        <Slide src={image1} isActive={activeIndex === 2} />
        <Slide src={image1} isActive={activeIndex === 3} />
        <Slide src={image1} isActive={activeIndex === 4} />
      </div>

      <div className='small2'>
        <Slide src={image2} isActive={activeIndex === 0} />
        <Slide src={image2} isActive={activeIndex === 1} />
        <Slide src={image2} isActive={activeIndex === 2} />
        <Slide src={image2} isActive={activeIndex === 3} />
        <Slide src={image2} isActive={activeIndex === 4} />
      </div>
      
      <Content title="Are You" subTitle="looking for" dataAos="fade-up" isActive={activeIndex === 0} />
      <Content title="Consistent quality all" subTitle="through the year" dataAos="fade-up" isActive={activeIndex === 1} />
      <Content title="Minimise wastage" subTitle="in Kitchen" dataAos="fade-up" isActive={activeIndex === 2} />
      <Content title="Anbody" subTitle="Can Cook" dataAos="fade-up" isActive={activeIndex === 3} />
      <Content title="Reduce dependence" subTitle="on chef" dataAos="fade-up" isActive={activeIndex === 4} />
      <SliderNavigation numSlides={5} activeIndex={activeIndex} handleSlideChange={handleSlideChange} />
    </section>
    </Reveal>
  );
}

export default Imageslider;
