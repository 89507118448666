import React from 'react'
import Product from './Main_cont/Product';
import Navbar from './Main_cont/Navbar';
import Home from './Main_cont/Home';
import About from './Main_cont/About';
import './App.css';
import {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Contact_us from './Main_cont/Contact_us';
import Weare from './Main_cont/Weare'
import Imageslider from './Main_cont/Imageslider';


function Main_cont() {
  const { state } = useLocation(); 
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth"});
      console.log('scrolled')
    }
  });

  return (
    <div className='App'>
        <Home />
        <Weare/>
        <About />
        <Product />
        <Imageslider/>
        <Contact_us/>
    </div>
  )
}

export default Main_cont