import React from 'react';
import './Router_page.css';
import "../Features/script";
import Navbar from './Navbar'
import img from '../assests/Screenshot 2024-02-04 164701.png'

function Router_Page() {
  return (
    <div>
        <div className='route_page' id='fruits-vegetables'>
            <div className='Title'>
                <h1>DEHYDRATED FRUITS AND VEGETABLES</h1>
                <h2><br/> All kinds of vegetables and fruits are available</h2>
            </div>
            <div className='image'>
                    <img src={img} alt='table_image'/>
            </div>
        </div>
    </div>
  )
}

export default Router_Page